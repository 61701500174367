import * as React from 'react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import './index.css';

const ceremonySeating: string[][] = [
  [
    'Aaliyah',
    'Nina',
    'Alan',
    'Kerry',
    'Row 1',
    'Sharron',
    'Shaun',
    'Alison',
    'Neil',
  ],
  ['Joe', 'Jaide', 'Jason', 'Jess', 'Row 2', 'Abby', 'Luke', 'Ray', 'Jenny'],
  [
    'Sarah',
    'Ant',
    'Pete',
    'Sharon',
    'Row 3',
    'Rosemarie',
    'Tony',
    'Jonny',
    'Janeifar',
  ],
  [
    'Amy & Iris',
    'Jim',
    'Billy',
    'Amy',
    'Row 4',
    'Jane Hart',
    'Miroslava',
    'Anton',
    'Sean',
  ],
  [
    'Dean',
    'Leila',
    'Molly',
    'Claire',
    'Row 5',
    'Cara',
    'Chloe',
    'Frida',
    'Mona',
  ],
  [
    'Gary',
    'Michelle',
    'Lee',
    'Sarah',
    'Row 6',
    'Timbo',
    'Michael',
    'Harry',
    'Mehnaz',
  ],
  [
    'Christa',
    'Kevin',
    'Don',
    'Jane',
    'Row 7',
    'Nicola',
    'Beth',
    'Emily',
    'Bronny',
  ],
];

const loaners = {
  name: 'South Wales',
  people: ['Heather Carby', 'Nick Fitton'],
};

const receptionSeating: { name: string; people: string[] }[] = [
  {
    name: 'Yorkshire',
    people: [
      'Neil Fitton',
      'Ali Fitton',
      'Jane Hart',
      'Rosemarie Fitton',
      'Tony Matters',
      'Ray Fitton',
      'Jenny Fitton',
    ],
  },
  {
    name: 'Bristol',
    people: [
      'Sharon Thompson',
      'Pete Thompson',
      'Amy Thompson',
      'Anthony Grainger',
      'Sarah Dudley',
      'Billy Grainger',
      'James Grainger',
      'Amy Le Huquet',
      'Iris Grainger',
    ],
  },
  {
    name: 'Mansfield',
    people: [
      'Kerry Carby',
      'Alan Carby',
      'Nina Carby',
      'Johan Demoniere',
      'Aaliyah Demoniere',
      'Claire Grainger',
      'Molly Watson',
      'Leila Watson',
      'Dean Watson',
    ],
  },
  {
    name: 'Sussex',
    people: [
      'Sharron Thomas',
      'Shaun Thomas',
      'Abby Fitton',
      'Luke Polos',
      'Sean Fuller',
      'Miroslava Fuller',
      'Anton Fuller',
      'Jonny Fuller',
      'Janeifar Lawrance',
    ],
  },
  {
    name: 'Middlesex',
    people: [
      'Jess Thompson',
      'Jason Thompson',
      'Mona Otterstad',
      'Frida Solheim',
      'Timbo Cole',
      'Harry Hamilton',
      'Mehnaz Hamilton',
      'Michael Heeney',
    ],
  },
  {
    name: 'Derbyshire',
    people: [
      'Don Hill',
      'Jane Hill',
      'Kevin Freer',
      'Christa Freer',
      'Gary Carby',
      'Michelle Davis',
      'Sarah Hallam',
      'Lee Hallam',
    ],
  },
  {
    name: 'Hertfordshire',
    people: [
      'Jaide Spilhaus',
      'Joe Steel',
      'Chloe Bowker',
      'Cara Davison',
      'Nicola Everill',
      'Emily Roberts',
      'Beth Jones',
      'Bronny Salter',
    ],
  },
];

const VenuePage = () => (
  <Layout>
    <Seo title="Seating" />
    <div className="content">
      <h1>The Seating Plans</h1>
      <hr />
      <section>
        <h2>CEREMONY</h2>
        <p>
          A seating plan will be printed and displayed before you enter The
          Orangery. Please use this plan to find and take your seat ready for
          the wedding party's entrance. Afterwards, confetti will be available
          in a basket at the back of the room, please grab a handful on your way
          out.
        </p>
        <br />
        <div className="wed">
          <span>Nick & Heather</span>
        </div>
        <div className="seating-plan ceremony">
          {ceremonySeating.map(row =>
            row.map((person, i) => (
              <div className={i === 4 ? 'row' : ''}>
                <span className={i === 4 ? 'row' : ''}>{person}</span>
              </div>
            ))
          )}
        </div>
      </section>
      <hr />
      <section>
        <h2>RECEPTION</h2>
        <p>
          Please find the table plan for our "late lunch wedding breakfast"
          below. Like above, this plan will be printed and displayed in the
          reception room before the meal begins.
        </p>
        <Table {...loaners} />
        <div className="seating-plan reception row-1">
          {receptionSeating.slice(0, 4).map(({ name, people }) => {
            return <Table name={name} people={people} />;
          })}
        </div>
        <div className="seating-plan reception row-2">
          {receptionSeating.slice(4).map(({ name, people }) => {
            return <Table name={name} people={people} />;
          })}
        </div>
      </section>
    </div>
  </Layout>
);

export default VenuePage;

const Table = ({ name, people }: { name: string; people: string[] }) => {
  return (
    <div className="table">
      <h2>{name}</h2>
      {people.map(person => (
        <>
          <span>{person}</span>
        </>
      ))}
    </div>
  );
};
